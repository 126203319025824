import { usePersistedState } from "@/components/cookies/hooks/usePersistedState"
import { Button } from "@/components/ui/button"
import { SrOnly } from "@/components/ui/sr-only"
import * as AccordionPrimitive from "@radix-ui/react-accordion"
import { ChevronDown } from "lucide-react"

const GroupContext = React.createContext<{ state: string; setState: (state: string) => void }>({
  state: "",
  setState: () => {},
})
const ItemContext = React.createContext<{ name: string; isOpen: boolean }>({
  name: "",
  isOpen: false,
})

/**
 * CardRoot
 */
const CardRoot = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Root>,
  Omit<
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Root>,
    "value" | "type" | "defaultValue" | "onValueChange"
  > & {
    group: string
    defaultValue: string
    // defaultValue: string[]
  }
>(({ className, group, defaultValue, children, ...props }, ref) => {
  const [state, setState] = usePersistedState(
    defaultValue,
    `toggle-${group}`,
    "interface",
    sessionStorage
  )
  return (
    <GroupContext.Provider value={{ state, setState }}>
      <AccordionPrimitive.Root
        type="single"
        collapsible
        // type="multiple"
        className={cx(
          "group/card @container/card grid gap-4 w-full max-w-screen-2xl mx-auto",
          className
        )}
        defaultValue={defaultValue}
        value={state}
        onValueChange={setState}
        ref={ref}
        {...props}
      >
        {children}
      </AccordionPrimitive.Root>
    </GroupContext.Provider>
  )
})

/**
 * CardItem
 */
const CardItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, children, value, ...props }, ref) => {
  const context = React.useContext(GroupContext)

  return (
    <ItemContext.Provider value={{ name: value, isOpen: context.state === value }}>
      <AccordionPrimitive.Item
        className={cx(
          "grid w-full bg-card rounded-md border border-border/50 shadow-sm",
          className
        )}
        value={value}
        {...props}
        ref={ref}
      >
        {children}
      </AccordionPrimitive.Item>
    </ItemContext.Provider>
  )
})

/**
 * CardHeader
 */
const CardHeader = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Header>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Header>
>(({ className, children, ...props }, ref) => {
  const { setState, state } = React.useContext(GroupContext)
  const { name } = React.useContext(ItemContext)
  const toggle = () => setState(name === state ? "" : name)
  return (
    <div
      className={cx(
        "flex justify-between items-center w-full px-4 @lg/card:px-8 py-4 gap-8",
        className
      )}
      {...props}
      ref={ref}
      onClick={toggle}
    >
      {children}
    </div>
  )
})

/**
 * CardHeaderTitle
 */
const CardHeaderTitle = React.forwardRef<
  React.ElementRef<"h2">,
  React.ComponentPropsWithoutRef<"h2">
>(({ className, children, ...props }, ref) => {
  return (
    <h2
      className={cx(
        "flex items-center gap-2 text-base font-normal leading-none tracking-tight",
        className
      )}
      {...props}
      ref={ref}
    >
      {children}
    </h2>
  )
})

/**
 * CardHeaderAside
 * dictionary src/dictionaries/en/components/cms.json
 */
const CardHeaderAside = React.forwardRef<
  React.ElementRef<"div">,
  React.ComponentPropsWithoutRef<"div">
>(({ className, children }, ref) => {
  const { _ } = useDictionary("components.cms")
  return (
    <div
      className={cx("flex items-center gap-2", className)}
      ref={ref}
      onClick={e => e.stopPropagation()}
    >
      {children}
      <AccordionPrimitive.Trigger asChild>
        <Button variant="ghost" size="xxs" icon className="[&[data-state=open]>svg]:rotate-180">
          <ChevronDown aria-hidden className="size-4 shrink-0 transition-transform duration-200" />
          <SrOnly>{_("accordion-toggle")}</SrOnly>
        </Button>
      </AccordionPrimitive.Trigger>
    </div>
  )
})

/**
 * CardContent
 */
const CardContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => {
  const { isOpen } = React.useContext(ItemContext)
  return (
    <AccordionPrimitive.Content
      className={cx(
        "overflow-hidden transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
      )}
      {...props}
      ref={ref}
    >
      {isOpen && <div className={cx(className)}>{children}</div>}
    </AccordionPrimitive.Content>
  )
})

export const CardAccordion = Object.assign(CardRoot, {
  Item: CardItem,
  Header: Object.assign(CardHeader, {
    Title: CardHeaderTitle,
    Aside: CardHeaderAside,
  }),
  Content: CardContent,
})
