import { Form, FormAssertive, FormInput, FormSubmit, useForm, validator } from "@/components/form"
import { formatDatetimeToFormInput } from "@/components/form/input"
import { FormNumber } from "@/components/form/number"
import { Button } from "@/components/ui/button"
import { confirmAlert } from "@/components/ui/confirm"
import { Dialog } from "@/components/ui/dialog"
import type { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { updateWorkshopEvent } from "@/store/workshops/actions"
import { WorkshopEvent } from "@/store/workshops/localizers"
import { match } from "ts-pattern"

/**
 * dictionary src/dictionaries/en/pages/dashboard/kultureralley/workshops.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "events")

/**
 * EditDialog
 */
export const EditDialog: React.FC<UseDialogProps<WorkshopEvent>> = ({ item, ...props }) => {
  const { _ } = useDictionary(dictionary("edit-dialog"))
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="max-w-2xl">
      {item !== false && <DialogForm {...props} item={item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps<WorkshopEvent>> = ({ item: event, onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  const _form = useFormDictionary()
  const _errors = useErrorsDictionary()
  const { max } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => ({
      datetime: formatDatetimeToFormInput(event.datetime),
      reservationsSlot: event.reservationsSlot,
    })),
    validate: validator({
      reservationsSlot: [max(10000, _("form.slots-max"))],
    }),
    onSubmit: async ({ values }) => {
      if (!form.isValid) return _errors("VALIDATION_FAILURE")

      const payload = {
        datetime: T.formatISO(T.parseISO(values.datetime)), // to avoid timezone issues
        reservationsSlot: values.reservationsSlot,
      }

      if (
        formatDatetimeToFormInput(event.datetime) !== values.datetime &&
        event.reservationsDone > 0
      )
        if (!(await confirmAlert({ dictionary: dictionary("edit-dialog.confirm-change-date") })))
          return

      return match(await updateWorkshopEvent(event.id, payload))
        .with({ error: false }, () => {
          toast.success(_("edit-dialog.success"))
          onOpenChange(false)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _errors)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_errors(code)))
        )
    },
  })

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormInput label={_("form.datetime-label")} name="datetime" type="datetime-local" />
      <FormNumber label={_("form.slots-label")} name="reservationsSlot" min={0} />
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
