import newsletterBG from "@/assets/images/newsletter.png"
import { HN } from "@/components/ui/hn"
import { textPlaceholder } from "@/fns/String"
import { useTranslation } from "@/store/languages/hooks"
import { frontendButtonVariants } from "../../frontend/button"
import { container } from "../../frontend/container"
import { ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"

/**
 * dictionary src/dictionaries/en/components/cms.json
 * dictionary src/dictionaries/fr/components/cms.json
 * dictionary src/dictionaries/de/components/cms.json
 */
const dictionary = createContextMapper("components", "cms")

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const { _ } = useDictionary(dictionary("content", "items", itemType))
  const t = useTranslation()
  const { titleLevel } = item.props
  const { title, secondary, label, button } = t(item).props
  return (
    <div
      style={{ backgroundImage: `url('${newsletterBG}')` }}
      className="bg-cover bg-center bg-no-repeat rounded-b-md"
    >
      <div className={cx("flex flex-col gap-8 py-7 xl:py-24 font-plus", container({ x: "sm" }))}>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
          <div className="flex flex-col gap-5">
            <HN
              level={titleLevel}
              className="text-4xl font-extrabold text-frontend-tomato font-plus uppercase tracking-wide"
            >
              {textPlaceholder(title, _("title-placeholder"))}
            </HN>
            <p className="text-frontend-bluewhale text-xl font-bold font-plus">
              {textPlaceholder(secondary, _("secondary-placeholder"))}
            </p>
          </div>
          <div className="flex flex-col xl:flex-row gap-2.5">
            <input
              placeholder={textPlaceholder(label, _("label-placeholder"))}
              className="w-full block px-5 py-4 bg-white h-[50px] border-[1px] border-frontend-cloud focus:outline-0 font-plus text-frontend-emperor"
            />
            <button className={frontendButtonVariants({ variant: "dark" })}>
              {textPlaceholder(button, _("button-placeholder"))}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
