import { Row } from "@/components/collection"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDateFnsLocale, useDictionary } from "@/dictionaries/hooks"
import { useNow } from "@/hooks/use-today"
import { Workshop } from "@/store/workshops/localizers"
import { ClockCountdown, GlobeHemisphereEast } from "@phosphor-icons/react"

/**
 * dictionary src/dictionaries/fr/pages/dashboard/kultureralley/workshops.json
 * dictionary src/dictionaries/de/pages/dashboard/kultureralley/workshops.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "workshops")

export const Publication: React.FC<{ workshop: Workshop; className?: string }> = ({
  workshop,
  className,
}) => {
  const { _ } = useDictionary(dictionary())
  const hide =
    !workshop.published || (workshop.publishedFrom === null && workshop.publishedTo === null)
  const now = useNow()
  const locale = useDateFnsLocale()
  const message = React.useMemo(() => {
    if (isAvailable(workshop, now)) return _("now-available")
    if (wasAvailable(workshop, now) && workshop.publishedTo)
      return _("was-available", {
        distance: T.formatDistance(workshop.publishedTo, now, {
          locale,
          addSuffix: true,
        }),
      })
    if (willBeAvailable(workshop, now) && workshop.publishedFrom)
      return _("will-be-available", {
        distance: T.formatDistance(workshop.publishedFrom, now, {
          locale,
          addSuffix: true,
        }),
      })
    return ""
  }, [locale, _, workshop, now])
  if (hide) return null
  return (
    <Row.Icon tooltip={message} className={cx("relative", className)}>
      {isAvailable(workshop, now) ? (
        <GlobeHemisphereEast aria-hidden />
      ) : wasAvailable(workshop, now) ? (
        <ClockCountdown aria-hidden />
      ) : (
        <ClockCountdown aria-hidden className="-rotate-90" />
      )}
      <span
        className={cx(
          "absolute right-1 top-1",
          "size-1.5 rounded-full transition-colors duration-500 ease-in-out",
          isAvailable(workshop, now) ? "bg-green-700" : "bg-slate-600"
        )}
        aria-hidden
      />
    </Row.Icon>
  )
}

/**
 * isAvailable
 * Check if an article is available
 */
export const isAvailable = (workshop: Workshop, now = new Date()) => {
  if (!workshop.published) return false
  const validFrom = workshop.publishedFrom ? workshop.publishedFrom : null
  const validUntil = workshop.publishedTo ? workshop.publishedTo : null
  const isAfterFrom = validFrom ? T.isAfter(now, validFrom) : true
  const isBeforeUntil = validUntil ? T.isBefore(now, validUntil) : true
  return isAfterFrom && isBeforeUntil
}

/**
 * willBeAvailable
 * Check if an article will be available
 */
export const willBeAvailable = (workshop: Workshop, now = new Date()) => {
  if (!workshop.published || !workshop.publishedFrom) return false
  return T.isAfter(workshop.publishedFrom, now)
}

/**
 * wasAvailable
 * Check if an article was available
 */
export const wasAvailable = (workshop: Workshop, now = new Date()) => {
  if (!workshop.published || !workshop.publishedTo) return false
  return T.isBefore(workshop.publishedTo, now)
}
