import { FormSwitch } from "@/components/cookies/form/switch"
import { Form, FormSubmit, useForm } from "@/components/form"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { UseDialogFormProps } from "@/components/ui/hooks/useDialog"
import { Switch } from "@/components/ui/switch"
import { cookieDeclarations } from "@/config/cookies"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import * as AccordionPrimitive from "@radix-ui/react-accordion"
import { ChevronDown } from "lucide-react"
import { CookieCategory, useCookies } from ".."

/**
 * dictionary src/dictionaries/fr/components/cookies.json
 */
const dictionary = createContextMapper("components", "cookies")

/**
 * ConsentDialog
 */
export const ManagePreferences: React.FC = () => {
  const { _ } = useDictionary(dictionary("manage-preferences"))
  const { setPreferences, preferences } = useCookies()

  return (
    <Dialog
      open={preferences}
      onOpenChange={setPreferences}
      title={_("title")}
      description={_("description")}
      className="max-w-2xl"
    >
      <ManagePreferencesForm onOpenChange={setPreferences} />
    </Dialog>
  )
}

const ManagePreferencesForm: React.FC<UseDialogFormProps<void>> = ({ onOpenChange }) => {
  const { _ } = useDictionary(dictionary("manage-preferences"))

  const categories = [...cookieDeclarations.categories]
  const { consent, acceptAll, rejectAll, setConsent, setIsConsented } = useCookies()
  console.log(consent)
  const form = useForm({
    values: useMemoOnce(() => ({
      ...consent,
    })),
    onSubmit: ({ values }) => {
      setConsent(values)
      onOpenChange(false)
      setIsConsented(true)
    },
  })

  // categories switch
  const onCheckedChange = (value: boolean, category: CookieCategory) =>
    value
      ? form.setValues(
          D.merge(
            form.values,
            pipe(
              [...cookieDeclarations.apps],
              A.filterMap(app =>
                A.includes(app.categories, category) ? ([app.name, true] as const) : O.None
              ),
              D.fromPairs
            )
          )
        )
      : form.setValues(
          D.merge(
            form.values,
            pipe(
              [...cookieDeclarations.apps],
              A.filterMap(app =>
                A.includes(app.categories, category) ? ([app.name, app.required] as const) : O.None
              ),
              D.fromPairs
            )
          )
        )
  const isChecked = (category: CookieCategory) =>
    pipe(
      cookieDeclarations.apps,
      A.filter(app => A.includes(app.categories, category)),
      A.every(app => form.values[app.name])
    )

  return (
    <Form form={form}>
      <AccordionPrimitive.Root
        type="single"
        defaultValue={categories[0]}
        collapsible
        className="flex flex-col gap-4"
      >
        {categories.map(category => (
          <Category
            key={category}
            category={category}
            checked={isChecked(category)}
            onCheckedChange={value => onCheckedChange(value, category)}
          />
        ))}
        <div className="flex gap-4 justify-between">
          <div className="flex gap-4">
            <Button
              variant="outline"
              onClick={() => {
                acceptAll()
                onOpenChange(false)
              }}
            >
              {_("accept-all")}
            </Button>
            <Button
              variant="outline"
              onClick={() => {
                rejectAll()
                onOpenChange(false)
              }}
            >
              {_("reject-all")}
            </Button>
          </div>
          <FormSubmit>{_("save")}</FormSubmit>
        </div>
      </AccordionPrimitive.Root>
    </Form>
  )
}

const Category: React.FC<{
  category: CookieCategory
  checked: boolean
  onCheckedChange: (value: boolean) => void
}> = ({ category, checked, onCheckedChange }) => {
  const { _ } = useDictionary(dictionary())
  const apps = cookieDeclarations.apps.filter(app => A.includes(app.categories, category))
  const required = React.useMemo(
    () =>
      pipe(
        [...cookieDeclarations.apps],
        A.filter(app => A.includes(app.categories, category)),
        A.every(app => app.required)
      ),
    [category]
  )
  if (A.isEmpty(apps)) return null
  return (
    <AccordionPrimitive.Item className="rounded-md border" value={category}>
      <AccordionPrimitive.Header className="flex items-center p-4 gap-2">
        <AccordionPrimitive.Trigger className="flex gap-4 grow [&[data-state=open]_svg:last-child]:rotate-180 text-sm font-medium">
          <span
            className="flex justify-center items-center bg-secondary rounded-full size-5"
            aria-hidden
          >
            <ChevronDown
              className="shrink-0 transition-transform duration-200"
              size={12}
              strokeWidth={1.5}
            />
          </span>
          {_(`categories.${category}.title`)}
        </AccordionPrimitive.Trigger>
        {required && <Badge variant="outline">{_("manage-preferences.required")}</Badge>}
        <Switch size="sm" checked={checked} onCheckedChange={onCheckedChange} disabled={required} />
      </AccordionPrimitive.Header>
      <AccordionPrimitive.Content className="flex flex-col overflow-hidden text-sm transition-all data-[state=closed]:animate--accordion-up data-[state=open]:animate--accordion-down data-[state=open]:border-t">
        <div className="p-4 flex flex-col gap-4">
          <p className="text-muted-foreground leading-tight">
            {_(`categories.${category}.description`)}
          </p>
          {apps.map(app => (
            <App key={app.name} app={app} />
          ))}
        </div>
      </AccordionPrimitive.Content>
    </AccordionPrimitive.Item>
  )
}

const App: React.FC<{ app: (typeof cookieDeclarations)["apps"][number] }> = ({ app }) => {
  const { _ } = useDictionary(dictionary())
  return (
    <div className="flex flex-col py-2 gap-2">
      <FormSwitch
        name={app.name}
        label={_(`apps.${app.name}.title`)}
        disabled={app.required}
        badge={_("manage-preferences.required")}
      />
      <p className="text-muted-foreground leading-tight">{_(`apps.${app.name}.description`)}</p>
    </div>
  )
}
