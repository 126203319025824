import {
  Form,
  FormAssertive,
  FormHeader,
  FormInput,
  FormKeywords,
  FormSection,
  FormSubmit,
  FormTiptap,
  durationToTime,
  timeToDuration,
  useForm,
  validator,
} from "@/components/form"
import { formatDatetimeToFormInput } from "@/components/form/input"
import { FormSwitchNoLabel } from "@/components/form/switch"
import { Prose } from "@/components/frontend/prose"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import type { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { updateWorkshop } from "@/store/workshops/actions"
import { Workshop } from "@/store/workshops/localizers"
import * as CollapsiblePrimitive from "@radix-ui/react-collapsible"
import { match } from "ts-pattern"

/**
 * dictionary src/dictionaries/fr/pages/dashboard/kultureralley/workshops.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "workshops")

/**
 * EditDialog
 */
export const EditDialog: React.FC<UseDialogProps<Workshop>> = ({ item, ...props }) => {
  const { _ } = useDictionary(dictionary("edit-dialog"))
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="max-w-2xl">
      {item !== false && <DialogForm {...props} item={item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps<Workshop>> = ({ item: workshop, onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  const _form = useFormDictionary()
  const _errors = useErrorsDictionary()
  const { min, isSetAndBeforeIso, isSetAndAfterIso } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => ({
      ...D.selectKeys(workshop, basicFields),
      duration: durationToTime(workshop.duration),
      maxAttendees: `${workshop.maxAttendees}`,
      isScheduled: workshop.publishedFrom || workshop.publishedTo ? true : false,
      publishedFrom: formatDatetimeToFormInput(workshop.publishedFrom),
      publishedTo: formatDatetimeToFormInput(workshop.publishedTo),
    })),
    validate: validator({
      name: [min(1, _form("name-required"))],
      publishedFrom: [isSetAndBeforeIso("publishedTo", _("form.published-from-invalid"))],
      publishedTo: [isSetAndAfterIso("publishedFrom", _("form.published-to-invalid"))],
    }),
    onSubmit: async ({ values }) => {
      if (!form.isValid) return _errors("VALIDATION_FAILURE")

      const payload = {
        ...D.selectKeys(values, basicFields),
        maxAttendees: +values.maxAttendees,
        duration: timeToDuration(values.duration),
        publishedFrom:
          values.isScheduled && values.publishedFrom
            ? T.formatISO(new Date(values.publishedFrom))
            : null,
        publishedTo:
          values.isScheduled && values.publishedTo
            ? T.formatISO(new Date(values.publishedTo))
            : null,
      }
      return match(await updateWorkshop(workshop.id, payload))
        .with({ error: false }, () => {
          toast.success(_("edit-dialog.success"))
          onOpenChange(false)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _errors)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_errors(code)))
        )
    },
  })
  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormInput label={_("form.name-label")} name="name" placeholder={_form("name-placeholder")} />
      <FormTiptap label={_("form.description-label")} name="description" prose={Prose.className} />
      <FormKeywords
        label={_("form.languages-label")}
        name="languages"
        placeholder={_("form.languages-placeholder")}
      />
      <FormInput type="time" label={_("form.duration-label")} name="duration" />
      <FormInput type="number" label={_("form.max-attendees-label")} name="maxAttendees" />
      <CollapsiblePrimitive.Root open={form.values.isScheduled} className="space-y-4">
        <div className="flex gap-4">
          <FormHeader>
            <FormHeader.Title>{_("form.section-publication-title")}</FormHeader.Title>
            <FormHeader.Description>
              {_("form.section-publication-description")}
            </FormHeader.Description>
          </FormHeader>
          <div className="pt-3">
            <FormSwitchNoLabel name="isScheduled" size="sm" />
          </div>
        </div>
        <CollapsiblePrimitive.Content className="overflow-hidden data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down">
          <FormSection className="flex flex-col gap-4 p-4 border border-input rounded-md">
            <FormInput
              label={_("form.published-from-label")}
              name="publishedFrom"
              type="datetime-local"
            />
            <FormInput
              label={_("form.published-to-label")}
              name="publishedTo"
              type="datetime-local"
            />
          </FormSection>
        </CollapsiblePrimitive.Content>
      </CollapsiblePrimitive.Root>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}

/**
 * basicFields
 */
const basicFields = ["name", "description", "languages"] as const
