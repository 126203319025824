import { useDictionary } from "@/dictionaries/hooks"
import { useFilterable } from "@/hooks/useFilterable"
import { useLimitable } from "@/hooks/useLimitable"
import { useMatchable } from "@/hooks/useSearchable"
import { useSortable } from "@/hooks/useSortable"
import { useProgramsStore } from "."
import { getUniqueClasses } from "./helpers"
import { Program } from "./localizers"

/**
 * dictionary src/dictionaries/en/pages/dashboard/programs.json
 */
const dictionary = createContextMapper("pages", "dashboard", "programs")

/**
 * Hooks
 */
export const usePrograms = () => {
  const byIds = useProgramsStore(D.getUnsafe("programs"))
  const programs = React.useMemo(() => {
    return D.values(byIds)
  }, [byIds])
  return programs
}
export const useProgramsById = () => {
  const byIds = useProgramsStore(D.getUnsafe("programs"))
  return byIds
}
export const useProgram = (id: string | unknown) => {
  const program = useProgramsStore(({ programs }) =>
    G.isString(id) ? D.get(programs, id) : undefined
  )
  return program
}

export const useProgramClasses = () => {
  const byIds = useProgramsStore(D.getUnsafe("programs"))
  const classes = React.useMemo(() => {
    return getUniqueClasses(D.values(byIds))
  }, [byIds])
  return classes
}
export const useProgramClassOptions = () => {
  const classes = useProgramClasses()
  const classOptions = React.useMemo(
    () =>
      pipe(
        classes,
        A.map(item => ({
          value: item,
          label: item,
        })),
        A.sortBy(D.prop("label"))
      ),
    [classes]
  )
  return classOptions
}
export const useProgramLanguageOptions = () => {
  const { _ } = useDictionary(dictionary("form"))
  const languageOptions = React.useMemo(
    () =>
      (["fr", "de", "en"] as const).map(item => ({
        value: item,
        label: _(`language-${item}`),
      })),
    [_]
  )
  return languageOptions
}

export const useFilteredPrograms = (initialLimit = 24, initialByMore = 12) => {
  const { _ } = useDictionary(dictionary())
  const programs = usePrograms()

  const [filterable, filterBy] = useFilterable<Program>(
    "programs",
    {
      archived: ({ archived }) => archived,
    },
    { archived: false }
  )

  const [matchable, matchIn] = useMatchable<Program>([
    "name",
    "code",
    "class",
    "language",
    ({ language }) => _(`language-${language as "en"}`),
  ])

  const [sortable, sortBy] = useSortable<Program>(
    "programs",
    {
      name: program => program.name,
      class: program => program.class,
      code: program => program.code,
      clicks: program => program.programsVisits + program.booksVisits + program.pearltreesVisits,
    },
    "name"
  )
  const filtered = React.useMemo(
    () => pipe(programs, filterBy, S.isEmpty(S.trim(matchable.search)) ? sortBy : matchIn),
    [programs, filterBy, matchable.search, matchIn, sortBy]
  )

  const [limitable, limit] = useLimitable<Program>(filtered.length, initialLimit, initialByMore)

  return {
    programs,
    filtered,
    filterable,
    matchable,
    sortable,
    limitable,
    limit,
  }
}

/**
 * types
 */
export type ProgramsFiltersKey = "archived" | "class"
