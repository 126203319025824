import {
  Form,
  FormAssertive,
  FormInput,
  FormNumber,
  FormSubmit,
  FormTimes,
  FormToggle,
  useForm,
  validator,
} from "@/components/form"
import { formatDatetimeToFormInput } from "@/components/form/input"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import type { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { Tabs } from "@/components/ui/tabs"
import { resetAllStoresAndReload } from "@/store"
import { createWorkshopEvent } from "@/store/workshops/actions"
import { match } from "ts-pattern"
import { usePageContext } from "./Context"

/**
 * dictionary src/dictionaries/fr/pages/dashboard/kultureralley/events.json
 * dictionary src/dictionaries/de/pages/dashboard/kultureralley/events.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "events")

/**
 * CreateDialog
 */
export const CreateDialog: React.FC<UseDialogProps> = ({ ...props }) => {
  const { _ } = useDictionary(dictionary("create-dialog"))
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="max-w-2xl">
      <DialogForm {...props} />
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps> = ({ onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  const _form = useFormDictionary()
  const _errors = useErrorsDictionary()
  const { workshop } = usePageContext()
  const { max, min } = validator
  const formSingle = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: {
      datetime: formatDatetimeToFormInput(new Date()),
      reservationsSlot: 1,
    },
    validate: validator({
      datetime: [min(1, _("form.datetime-required"))],
      reservationsSlot: [max(10000, _("form.slots-max"))],
    }),
    onSubmit: async ({ values }) => {
      if (!formSingle.isValid) return _errors("VALIDATION_FAILURE")
      const payload = {
        workshopId: workshop.id,
        datetime: T.formatISO(T.parseISO(values.datetime)), // to avoid timezone issues
        reservationsSlot: values.reservationsSlot,
      }
      return match(await createWorkshopEvent(payload))
        .with({ error: false }, () => {
          toast.success(_("create-dialog.success"))
          onOpenChange(false)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _errors)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_errors(code)))
        )
    },
  })
  const formRecurring = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: {
      from: formatDatetimeToFormInput(new Date()),
      to: formatDatetimeToFormInput(new Date()),
      days: [] as string[],
      times: [] as string[],
      reservationsSlot: 1,
    },
    validate: validator({
      from: [min(1, _("form.datetime-required"))],
      reservationsSlot: [max(10000, _("form.slots-max"))],
    }),
    onSubmit: async ({ values }) => {
      if (!formRecurring.isValid) return _errors("VALIDATION_FAILURE")
      if (A.isEmpty(values.times) || A.isEmpty(values.days)) return onOpenChange(false)

      const interval = T.eachDayOfInterval({
        start: T.parseISO(values.from),
        end:
          S.isEmpty(values.to) || T.isAfter(T.parseISO(values.from), T.parseISO(values.to))
            ? T.parseISO(values.from)
            : T.parseISO(values.to),
      })
      const days = A.map(values.days, Number)
      const dates = A.reduce(interval, [] as string[], (dates, date) =>
        A.includes(days, T.getDay(date))
          ? [
              ...dates,
              ...A.map(
                values.times,
                time => `${T.formatISO(date, { representation: "date" })}T${time}`
              ),
            ]
          : dates
      )
      await Promise.all(
        A.map(dates, datetime =>
          createWorkshopEvent({
            workshopId: workshop.id,
            datetime,
            reservationsSlot: values.reservationsSlot,
          })
        )
      )
      toast.success(_("create-dialog.success"))
      onOpenChange(false)
    },
  })

  return (
    <Tabs defaultValue="one" className="w-full">
      <Tabs.List className="max-md:flex max-md:flex-col max-md:items-stretch max-md:gap-2 max-md:h-auto md:grid md:grid-cols-2 w-full">
        <Tabs.Trigger value="one">{_("form.tab-single")}</Tabs.Trigger>
        <Tabs.Trigger value="recurring">{_("form.tab-recurring")}</Tabs.Trigger>
      </Tabs.List>
      <Tabs.Content value="one" className="flex flex-col gap-6 p-4 border">
        <Form form={formSingle} className="grid gap-6">
          <FormAssertive />
          <FormNumber label={_("form.slots-label")} name="reservationsSlot" min={0} />
          <FormInput label={_("form.datetime-label")} name="datetime" type="datetime-local" />
          <Dialog.Footer className="sm:justify-start">
            <Dialog.Close asChild>
              <Button variant="secondary">{_form("cancel")}</Button>
            </Dialog.Close>
            <FormSubmit>{_form("create")}</FormSubmit>
          </Dialog.Footer>
        </Form>
      </Tabs.Content>
      <Tabs.Content value="recurring" className="flex flex-col gap-6 p-4 border">
        <Form form={formRecurring} className="grid gap-6">
          <FormAssertive />
          <FormNumber label={_("form.slots-label")} name="reservationsSlot" min={0} />
          <div className="grid md:grid-cols-2 gap-6">
            <FormInput label={_("form.from-label")} name="from" type="date" />
            <FormInput label={_("form.to-label")} name="to" type="date" />
          </div>
          <FormToggle
            label={_("form.days-label")}
            name="days"
            size="sm"
            className="flex flex-wrap w-full gap-2"
            options={[
              {
                label: _("form.days-monday"),
                value: "1",
              },
              {
                label: _("form.days-tuesday"),
                value: "2",
              },
              {
                label: _("form.days-wednesday"),
                value: "3",
              },
              {
                label: _("form.days-thursday"),
                value: "4",
              },
              {
                label: _("form.days-friday"),
                value: "5",
              },
              {
                label: _("form.days-saturday"),
                value: "6",
              },
              {
                label: _("form.days-sunday"),
                value: "0",
              },
            ]}
          />
          <FormTimes label={_("form.times-label")} name="times" />
          <Dialog.Footer className="sm:justify-start">
            <Dialog.Close asChild>
              <Button variant="secondary">{_form("cancel")}</Button>
            </Dialog.Close>
            <FormSubmit>{_form("create")}</FormSubmit>
          </Dialog.Footer>
        </Form>
      </Tabs.Content>
    </Tabs>
  )
}
