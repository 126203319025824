import { inDev } from "@/config/global"

import { Button, buttonVariants } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import { FallbackProps } from "react-error-boundary"
import Linkify from "react-linkify"
import { useLocation } from "wouter"

/**
 * dictionary src/dictionaries/en/components/layout.json
 */
const dictionary = createContextMapper("components", "layout", "boundary-error")

/**
 * PageBoundary
 */
export const PageBoundary: React.FC<FallbackProps> = ({ error }) => {
  const { _ } = useDictionary(dictionary())
  const refresh = () => window.location.reload()

  // redirect to dashboard if on dashboard or home if on frontend
  const [location] = useLocation()
  const home = S.startsWith(location, "/dashboard") ? "/dashboard" : "/"

  return (
    <div
      className="flex justify-center items-center w-full min-h-screen p-8 bg-secondary"
      role="status"
    >
      <Card className="w-full max-w-4xl">
        <Card.Header>
          <Card.Title>{_("title")}</Card.Title>
          <Card.Description>{_("secondary")}</Card.Description>
        </Card.Header>
        <Card.Content className="flex flex-col gap-6">
          <p className="text-sm font-light">{_("message")}</p>
          {inDev && G.isString(error.stack) && (
            <div className="p-8 bg-destructive/10 border border-destructive/25 rounded-md text-sm [&_a]:text-destructive font-medium italic whitespace-pre-wrap">
              <h3 className="text-lg font-medium text-destructive">Stack Trace</h3>
              <Linkify textDecorator={cleanUrl}>{error.stack}</Linkify>
            </div>
          )}
          <div className="flex gap-2">
            <Button onClick={refresh}>{_("button-refresh")}</Button>
            <a href={home} className={buttonVariants({ variant: "secondary" })}>
              {_("button-home")}
            </a>
          </div>
        </Card.Content>
      </Card>
    </div>
  )
}

/**
 * helpers
 */
const shortenUrl = (url: string, n: number): string => {
  if (url.length <= n) return url
  const startChars = Math.floor((n - 3) / 2)
  const endChars = n - startChars - 3
  const shortenedUrl = `${url.slice(0, startChars)}...${url.slice(-endChars)}`
  return shortenedUrl
}
const cleanUrl = (url: string): string => {
  let cleanedUrl = url.replace(/^(https?:\/\/[^/]+)(\/)?/, "")
  const queryParamsIndex = cleanedUrl.indexOf("?")

  if (queryParamsIndex !== -1) {
    const beforeQueryParams = A.head(S.split(cleanedUrl, "?"))
    if (G.isNotNullable(beforeQueryParams)) cleanedUrl = beforeQueryParams
    const followingParams = S.split(url, ":")
    const rowNumber = +(followingParams[followingParams.length - 2] ?? "")
    const charNumber = +(followingParams[followingParams.length - 1] ?? "")
    if (N.gte(rowNumber, 0)) cleanedUrl += `:${rowNumber}`
    if (N.gte(charNumber, 0)) cleanedUrl += `:${charNumber}`
  }

  return shortenUrl(cleanedUrl, 60)
}
