import { z } from "zod"
import { apiPlace } from "../places/schemas"

/**
 * ApiContact
 */
export const apiContact = z.object({
  schoolName: z.string(),
  schoolClass: z.string(),
  schoolStreet: z.string(),
  schoolZip: z.string(),
  schoolCity: z.string(),
  teacherName: z.string(),
  teacherEmail: z.string(),
  teacherPhone: z.string(),
})
export type ApiContact = z.infer<typeof apiContact>

/**
 * ApiWorkshopReservation
 */
export const apiWorkshopReservation = z.object({
  id: z.string().uuid(),
  canceled: z.boolean(),
  archived: z.boolean(),
  message: z.string(),
  contact: z.union([apiContact, z.object({})]),
  language: z.string(),
  students: z.number(),
  userId: z.string(),
  eventId: z.string().uuid(),
  workshopId: z.string().uuid(),
  createdAt: z.string(),
  updatedAt: z.string(),
})
export type ApiWorkshopReservation = z.infer<typeof apiWorkshopReservation>

/**
 * ApiWorkshopEvent
 */
export const apiWorkshopEvent = z.object({
  id: z.string().uuid(),
  reservationsSlot: z.number(),
  datetime: z.string(),
  reservations: apiWorkshopReservation.array(),
  reservationsDone: z.number(),
  workshopId: z.string().uuid(),
})
export type ApiWorkshopEvent = z.infer<typeof apiWorkshopEvent>

/**
 * ApiWorkshopDuration
 */
export const apiWorkshopDuration = z.object({
  hours: z.number(),
  minutes: z.number(),
})
export type ApiWorkshopDuration = z.infer<typeof apiWorkshopDuration>

/**
 * ApiWorkshop
 */
export const apiWorkshop = z.object({
  id: z.string().uuid(),
  name: z.string(),
  published: z.boolean(),
  archived: z.boolean(),
  description: z.string(),
  languages: z.string().array(),
  duration: apiWorkshopDuration,
  maxAttendees: z.number(),
  placeId: z.string(),
  place: apiPlace,
  events: apiWorkshopEvent.array(),
  trackingId: z.string().uuid(),
  publishedFrom: z.string().nullable().optional(),
  publishedTo: z.string().nullable().optional(),
})
export type ApiWorkshop = z.infer<typeof apiWorkshop>
