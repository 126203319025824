import { H, Section } from "@/components/frontend/auto-heading"
import { Button } from "@/components/frontend/button"
import { ImageHeader } from "@/components/frontend/image-header"
import { containerVariants } from "@/components/layout/frontend/container"
import { Image } from "@/components/ui/image"
import { LinkExternal } from "@/components/ui/link"
import globalConfig from "@/config/global"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDateFnsLocaleFormat, useDictionary } from "@/dictionaries/hooks"
import { prependHttp } from "@/fns/String"
import { byId } from "@/fns/byId"
import { humanDuration } from "@/fns/human"
import { RegionName } from "@/fns/maps"
import { sortByProp } from "@/fns/sort"
import { useList } from "@/hooks/useList"
import { useData, usePromise } from "@/hooks/usePromise"
import { Note } from "@/pages/kulturrallye/Note"
import { regionLink, workshopLink } from "@/pages/kulturrallye/Routes"
import { service } from "@/services/frontend/service"
import {
  Place,
  Workshop,
  WorkshopEvent,
  localizePlace,
  localizeWorkshop,
} from "@/store/frontend/localizers"
import { CalendarBlank, Clock, Globe, MaskSad, Spinner, Ticket, User } from "@phosphor-icons/react"
import { isAfter } from "date-fns"
import { useTitle } from "hoofd"
import { ChevronRight, PhoneCall, Pin, SquareArrowOutUpRight } from "lucide-react"
import { match } from "ts-pattern"
import { Link, Redirect } from "wouter"
import { getGoogleMapsSearchLink } from "../PlacePreview"

/**
 * dictionary src/dictionaries/en/pages/kulturrallye.json
 */
const dictionary = createContextMapper("pages", "kulturrallye")
const mapDictionary = createContextMapper("components", "map")

/**
 * KulturrallyePlace
 */
const KulturrallyePlaces: React.FC<{ id: string; workshopId: Option<string> }> = props => {
  const { _ } = useDictionary(dictionary())

  const [place, inProgress] = useData(
    null,
    async () =>
      match(await service.places.read(props.id))
        .with({ error: false }, ({ data }) => localizePlace(data.place))
        .otherwise(() => null),
    [props.id]
  )

  const [workshops, loadingWorkshops] = useData(
    {},
    async () =>
      match(await service.places.workshops(props.id))
        .with({ error: false }, ({ data }) => byId(data.workshops, localizeWorkshop))
        .otherwise(() => ({})),
    [props.id]
  )

  useTitle(`${globalConfig.siteName} - ${_("name")}${place?.name ? `: ${place.name}` : ""}`)
  const workshopList = useList(workshops)

  if (inProgress)
    return (
      <Note>
        <Spinner />
      </Note>
    )
  if (G.isNullable(place))
    return (
      <Note>
        <MaskSad /> {_("place-not-found")}
      </Note>
    )
  const phone = A.head(place.phones)
  return (
    <div className="flex flex-col mb-44 gap-6 lg:gap-16 animate-appear-opacity">
      <div className={containerVariants({ x: "head" })}>
        <ImageHeader background={place.cover} backTo={regionLink(place.map)}>
          <ImageHeader.Main>
            <ImageHeader.Content>
              <ImageHeader.Title>{place.name}</ImageHeader.Title>
              <p className="flex items-center gap-2.5 flex-wrap">
                <span className="opacity-50 flex items-center gap-2.5 flex-wrap">
                  <RegionBacklink region={place.map} />
                  <ChevronRight className="w-5" aria-hidden />
                </span>
                {_("place.offers")}
              </p>
            </ImageHeader.Content>
          </ImageHeader.Main>
        </ImageHeader>
      </div>

      <div
        className={cx(
          containerVariants({ x: "sm" }),
          "w-full grid grid-cols-1 lg:grid-cols-[370px_1fr] gap-12"
        )}
      >
        <div className="flex flex-col gap-7">
          <div className="border-[1px] border-mercury px-6 py-6 flex flex-col gap-7">
            {place.image && (
              <Image className="aspect-video w-full object-contain max-h-40" src={place.image} />
            )}

            {/* Info */}
            <div className="flex flex-col gap-3">
              <h2 className="uppercase tracking-wider font-bold text-sm">{place.name}</h2>

              <div
                className="prose prose-sm"
                dangerouslySetInnerHTML={{ __html: place.description }}
              />
            </div>

            {/* Contacts */}
            <div className="flex flex-col gap-4">
              {S.isNotEmpty(S.trim(place.website)) && (
                <LinkExternal
                  className={cx("flex gap-2 text-sm hover:underline")}
                  href={prependHttp(place.website)}
                >
                  <SquareArrowOutUpRight className="w-4 text-tomato stroke-[1.2]" aria-hidden />
                  <span className="whitespace-pre-wrap">{place.website}</span>
                </LinkExternal>
              )}
              {S.isNotEmpty(S.trim(place.address)) && (
                <LinkExternal
                  className={cx("flex gap-2 text-sm hover:underline")}
                  href={getGoogleMapsSearchLink(place.address)}
                >
                  <Pin className="w-4 text-tomato stroke-[1.2]" aria-hidden />
                  <span className="whitespace-pre-wrap">{place.address}</span>
                </LinkExternal>
              )}
              {G.isNotNullable(phone) && S.isNotEmpty(S.trim(phone.value)) && (
                <LinkExternal
                  className={cx("flex gap-2 text-sm hover:underline")}
                  href={`tel:${phone.value}`}
                >
                  <PhoneCall className="w-4 text-tomato stroke-[1.2]" aria-hidden />
                  <span className="whitespace-pre-wrap">{phone.value}</span>
                </LinkExternal>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          {loadingWorkshops ? (
            <Note>
              <Spinner className={cx("fill-orient animate-slow-rotate")} weight="regular" />
            </Note>
          ) : (
            <PlaceWorkshops
              key={props.workshopId}
              workshopId={props.workshopId}
              workshops={workshopList}
              place={place}
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default KulturrallyePlaces

export const RegionBacklink: React.FC<{ region: RegionName }> = ({ region }) => {
  const { _ } = useDictionary()
  return (
    <Link to={regionLink(region)}>
      {_(dictionary("region"))} {_(mapDictionary(region)).toLowerCase()}
    </Link>
  )
}

/**
 * PlaceWorkshops
 */

const PlaceWorkshops: React.FC<{
  workshopId: Option<string>
  workshops: Workshop[]
  place: Place
}> = ({ workshops, workshopId, place }) => {
  const headWorkshop = A.head(workshops)
  const { _ } = useDictionary(dictionary("place", "workshop"))

  const [openWorkshop, loadingWorkshop] = usePromise(
    async () =>
      workshopId
        ? match(await service.workshops.read(workshopId))
            .with({ error: false }, ({ data }) => localizeWorkshop(data.workshop))
            .otherwise(() => null)
        : null,
    [workshopId]
  )

  if (!workshopId && headWorkshop) {
    return <Redirect to={workshopLink(headWorkshop)} replace />
  }

  if (workshops.length === 0)
    return (
      <Note>
        <MaskSad />
        {_("no-workshop", { name: place.name })}
      </Note>
    )

  return (
    <div className="flex flex-col">
      <div className="flex gap-3 pb-5 border-b-[1px] border-mercury flex-wrap">
        {workshops.map(workshop => (
          <Button
            key={workshop.id}
            to={workshopLink(workshop)}
            variant={workshop.id === workshopId ? "default" : "tab"}
            className="font-bold text-sm uppercase tracking-wide overflow-hidden"
            type="link"
            replace
          >
            <span className="truncate">{workshop.name}</span>
          </Button>
        ))}
      </div>

      <div className="flex flex-col">
        {loadingWorkshop ? (
          <Note>
            <Spinner />
          </Note>
        ) : openWorkshop ? (
          <PlaceWorkshop workshop={openWorkshop} key={openWorkshop.id} />
        ) : null}
      </div>
    </div>
  )
}

/**
 * PlaceWorkshop
 */

const PlaceWorkshop: React.FC<{ workshop: Workshop }> = ({ workshop }) => {
  const { _, language } = useDictionary(dictionary("place", "workshop"))

  const events = React.useMemo(() => Object.values(workshop.events), [workshop.events])
  const sortedEvents = React.useMemo(() => sortByProp({ datetime: isAfter }, events), [events])

  return (
    <Section className="flex flex-col py-8 gap-6">
      <H className="text-xl font-semibold">{workshop.name}</H>

      <Categories>
        <CategoryInfo>
          <CategoryStrong>
            <Clock />
            {_("duration")}
          </CategoryStrong>
          <span>{humanDuration(workshop.duration, language)}</span>
        </CategoryInfo>

        {workshop.languages.length > 0 && (
          <CategoryInfo>
            <CategoryStrong>
              <Globe />
              {_("languages")}
            </CategoryStrong>
            <span className="uppercase">{workshop.languages.join(", ")}</span>
          </CategoryInfo>
        )}

        <CategoryInfo>
          <CategoryStrong>
            <User />
            {_("participants")}
          </CategoryStrong>
          <span>{workshop.maxAttendees}</span>
        </CategoryInfo>
      </Categories>

      <div
        className="prose prose-p:text-[14px]"
        dangerouslySetInnerHTML={{ __html: workshop.description }}
      ></div>

      <Section className="w-full grid gap-5 grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3">
        {A.map(sortedEvents, event => (
          <EventRender key={event.id} event={event} />
        ))}
      </Section>
    </Section>
  )
}

const Categories = tw.div`flex flex-wrap py-6 px-7 gap-6 text-sm border-[1px] border-mercury rounded-sm`
const CategoryInfo = tw.p`flex items-center gap-2`
const CategoryStrong = tw.strong`flex text-sm items-center gap-2.5 [&_svg]:text-tomato [&_svg]:w-5 [&_svg]:h-auto`

/**
 * EventRender
 */
const EventRender: React.FC<{ event: WorkshopEvent }> = ({ event }) => {
  const { _ } = useDictionary(dictionary("place", "event"))
  const format = useDateFnsLocaleFormat()
  const leftoverSlots = Math.max(0, event.reservationsSlot - event.reservationsDone)
  const isEventBooked = leftoverSlots <= 0
  const buttonProps = match(isEventBooked)
    .with(true, () => ({ disabled: true }))
    .otherwise(() => ({
      type: "link" as const,
      href: `/kulturrallye/reservation/${event.id}`,
    }))

  return (
    <article
      className={cx(
        "border-[1px] p-2.5 flex flex-col items-stretch gap-3",
        isEventBooked ? "bg-alabaster border-mercury grayscale" : "bg-aquahaze border-solitude"
      )}
    >
      <div className="flex flex-col gap-5 p-4">
        <H className="text-2xl font-bold">{format(event.datetime, "P")}</H>
        <div className="flex gap-x-4 gap-y-2.5 flex-wrap">
          <CategoryStrong>
            <CalendarBlank /> {format(event.datetime, "EEEE")}
          </CategoryStrong>

          <CategoryStrong>
            <Clock /> {format(event.datetime, "p")}
          </CategoryStrong>

          {!isEventBooked && (
            <CategoryStrong>
              <Ticket /> {leftoverSlots} {_("slotsLeft")}
            </CategoryStrong>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-3 grow justify-end">
        <Button {...buttonProps} variant="dark">
          {isEventBooked ? _("soldOut") : _("reserve")}
        </Button>
      </div>
    </article>
  )
}
